import React, { useState } from "react";

const CardProduct = ({
  pretitle,
  title,
  description,
  accordionText
}) => {
  const [showAccordion, setShowAccordion] = useState(false);

  return (
    <div className={`CardProduct ${showAccordion ? 'is-toggle' : ''}`} onClick={() => setShowAccordion(!showAccordion)}>
      <h4 className="CardProduct__pretitle">{pretitle}</h4>
      <h3 className="CardProduct__title">{title}</h3>
      <div className="CardProduct__accordion">
        <div className="CardProduct__accordionBody">
          {
            description &&
              <p className="CardProduct__description">{description}</p>
          }
          {
            accordionText &&
              <>
                <h4>Ingredienti e allergeni</h4>
                <div dangerouslySetInnerHTML={{__html: accordionText}}></div>
              </>
          }
        </div>
      </div>
    </div>
  );
}

export default CardProduct;
