import React from "react";

const Jumbotron = ({
  image,
  title
}) => {
  return (
    <div className="Jumbotron">
      <div className="Jumbotron__image">
        <img src={image} alt="" />
      </div>
      <div className="Jumbotron__title">
        <img src={title} alt="" />
      </div>
    </div>
  );
}

export default Jumbotron;
