import React from 'react';

const Tabs = ({
  items,
  active,
  onChange
}) => {
  return (
    <nav className="Tabs">
      <ul>
        {
          items.map((item, index) => {
            return (
              <li key={index}>
                <button
                  className={`Tabs__button ${active.id === item.id ? 'is-active' : ''}`}
                  onClick={() => onChange(item)}
                >
                  {item.name}
                </button>
              </li>
            )
          })
        }
      </ul>
    </nav>
  );
};

export default Tabs;
