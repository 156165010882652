import React, { useState, useEffect, useCallback } from "react";

import Jumbotron from './components/Jumbotron';
import Tabs from './components/Tabs';
import Loader from './components/Loader';
import Searchbar from './components/Searchbar';
import CardProduct from './components/CardProduct';

import BackgroundImage from './images/autogrill-2-01.jpg';
import TextImage from './images/autogrill-text-01.svg';

const ALL_CATEGORY_ID = -1;
const UNRECOGNIZE_CATEGORY_ID = -2;

function App() {
  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [query, setQuery] = useState('');
  const [loader, setLoader] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const onCategoryChange = useCallback(category => {
    setCurrentCategory(category);
    setTimeout(() => {
      scrollToTop();
    }, 150);
  }, [currentCategory]);

  useEffect(() => {
    if (loader) {
      scrollToTop();
    }
  }, [loader])

  useEffect(() => {
    if (!loader) {
      setCurrentCategory({
        name: 'Tutti',
        id: -1
      });
      scrollToTop();
    }
  }, [query])

  useEffect(() => {
    if (!loader && products.length > 0) {
      if (currentCategory.id === ALL_CATEGORY_ID && query.length === '') {
        setFilteredProducts(products);
      } else {
        let filtered = products.filter(product => {
          return product.name.toLowerCase().includes(query.toLowerCase()) || product.description.toLowerCase().includes(query.toLowerCase())
        });

        if (currentCategory.id !== ALL_CATEGORY_ID) {
          filtered = filtered.filter(product => {
            if (currentCategory.id === UNRECOGNIZE_CATEGORY_ID) {
              return product.categories_id === 0
            } else {
              return product.categories_id === currentCategory.id
            }
          });
        }

        setFilteredProducts(filtered);
      }
    }
  }, [loader, currentCategory, products, query])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setLoader(true);

    try {
      const {
        REACT_APP_CLIENT_ID,
        REACT_APP_CLIENT_SECRET,
        REACT_APP_API_URL
      } = process.env;

      let shopCodeFromUrl = null;
      const shopCodeFromStorage = window.localStorage.getItem('shop_code');

      if (window.URLSearchParams) {
        const urlParams = new URLSearchParams(window.location.search);
        shopCodeFromUrl = urlParams.get('shop_code');
      } else {
        shopCodeFromUrl = window.getUrlParameter('shop_code');
      }

      let shopCode = null;

      if (shopCodeFromUrl !== null && shopCodeFromUrl !== shopCodeFromStorage) {
        shopCode = shopCodeFromUrl;
        window.localStorage.setItem('shop_code', shopCode);
      } else {
        shopCode = shopCodeFromStorage;
      }

      if (!shopCode) {
        throw new Error();
      }

      const getTokenRequest = await fetch(`${REACT_APP_API_URL}/oauth2/`, {
        method: 'POST',
        body: JSON.stringify({
          client_id: REACT_APP_CLIENT_ID,
          client_secret: REACT_APP_CLIENT_SECRET
        })
      });

      const { access_token } = await getTokenRequest.json();

      const getIngredientsRequest = await fetch(`${REACT_APP_API_URL}/v3/products/signIngredients/?shop_code=${shopCode}`, {
        method: 'POST',
        headers: {
          'Token': access_token
        }
      });

      const getIngredientsRespone = (await getIngredientsRequest.json());
      const { categories, products } = getIngredientsRespone.data;

      const allCategory = {
        name: 'Tutti',
        id: ALL_CATEGORY_ID
      };

      const unrecognizeCategory = {
        name: 'Altro',
        id: UNRECOGNIZE_CATEGORY_ID
      };

      let addUnrecognizedCategory = false;

      products.map(item => {
        if (item.categories_id === 0) {
          addUnrecognizedCategory = true;
        }
      });

      const totalCategories = [
        ...[allCategory],
        ...categories
      ];

      if (addUnrecognizedCategory) {
        totalCategories.push(unrecognizeCategory);
      }

      setCategories(totalCategories);
      setCurrentCategory(allCategory);
      setProducts(products);
    } catch (err) {
      setLoadingError(true);
    } finally {
      setLoader(false);
    }
  }, []);

  return (
    <div className={`App ${loader ? 'is-loading' : ''}`}>
      <div className="App__top">
        <Jumbotron
          image={BackgroundImage}
          title={TextImage}
        />
      </div>
      <div className="App__body">
        <Loader show={loader} />

        {
          !loadingError &&
            <>
            <div className="App__filter">
              <Tabs
                items={categories}
                active={currentCategory}
                onChange={category => onCategoryChange(category)}
              />
              <Searchbar
                placeholder="Cerca un prodotto"
                value={query}
                onChange={value => setQuery(value)}
              />
            </div>
            </>
        }

        <div className="App__results">
          {
            filteredProducts.length === 0 && !loadingError &&
              <p className="text-center m-2"><strong>Non ci sono risultati da mostrare</strong></p>
          }
          {
            filteredProducts.length === 0 && loadingError &&
              <p className="text-center m-2"><strong>Oops! Si è verificato un errore</strong></p>
          }
          {
            filteredProducts.map((item, index) => {
              return (
                <CardProduct
                  key={item.id}
                  pretitle={item.categories_name || 'Altro'}
                  title={item.name}
                  description={item.description}
                  accordionText={item.ingredients_and_allergens}
                />
              )
            })
          }
        </div>
      </div>
    </div>
  );
}

export default App;
