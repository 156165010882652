import React from "react";

const Loader = ({
  show
}) => {
  return (
    <div className={`Loader ${show ? 'is-visible' : ''}`}>
      <div className="Loader__inner"></div>
    </div>
  );
}

export default Loader;
