import React from "react";

const Searchbar = ({
  value,
  placeholder,
  onChange
}) => {
  return (
    <div className="Searchbar">
      <input
        className="Searchbar__input"
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );
}

export default Searchbar;
